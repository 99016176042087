import React from 'react'
import './HomePoster.scss'
import BannerDesktop from './pexels-francesco-ungaro-4322027 1.svg'
import BannerMobile from './pexels-francesco-ungaro-4322027.png'
import {Button, Container} from "react-bootstrap";

const HomePoster = () => {
    return (
        <div className="home-poster">
            <img className={'d-none d-sm-block'}
                alt="Home Banner"
                src={BannerDesktop}
            />
            <img className={'d-block d-sm-none'}
                 alt="Home Banner"
                 src={BannerMobile}
            />
            <Container className={'home-banner-wrapper d-flex flex-column align-items-center h-100 justify-content-center'}>
                <div className="home-poster-text align-self-center">
                    <h2 className={'secondary-title'}>We strive to be the first place to go to for ESG and impact driven entrepreneurs looking for funding to build successful global businesses that strongly impact the world positively</h2>
                </div>
            </Container>
            <div className="homePoster-about-us-btn">
                <Button size={'lg'} variant={'light'} href={'/about-us/'} className={'my-4 rounded-pill homePoster-about-us'}>About Us</Button>
            </div>
        </div>
    )
}

export default HomePoster
