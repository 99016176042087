import './App.scss';
import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./component/Home/Home";
import Team from "./component/Team/Team";
import News from "./component/News/News";
import Contact from "./component/Contact/Contact";
import Career from "./component/Career/Career";
import OpenRole from "./component/OpenRole/OpenRole";
import WhoWeAre from "./component/WhoWeAre/WhoWeAre";
import Pitch from "./component/Pitch/Pitch";
import Portfolio from "./component/Portfolio/Portfolio";
import About from "./component/About/About";
import Layout from "./component/Layout";
import PageNotFound from "./component/PageNotFound";


function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />} errorElement={<PageNotFound />}>
                <Route index element={<Home />} />
                <Route path="/Team" element={<Team />} />
                <Route path="/News" element={<News />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Contact-Us" element={<Contact />} />
                <Route path="/Career" element={<Career />} />
                <Route path="/Careers" element={<Career />} />
                <Route path="/OpenRole" element={<OpenRole />} />
                <Route path="/Open-Roles" element={<OpenRole />} />
                <Route path="/Whoweare" element={<WhoWeAre />} />
                <Route path="/who-we-are" element={<WhoWeAre />} />
                <Route path="/Pitch" element={<Pitch />} />
                <Route path="/pitch-us" element={<Pitch />} />
                <Route path="/pitch-us/funding" element={<Pitch type={'funding'}/>} />
                <Route path="/pitch-us/selling" element={<Pitch type={'selling'}/>} />
                <Route path="/Portfolio" element={<Portfolio />} />
                <Route path="/About" element={<About />} />
                <Route path="/about-us" element={<About />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}

export default App;
