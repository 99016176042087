import React from 'react'
import AboutTeam from './AboutTeam'
import Teams from './Teams'
import JoinOurTeam from './JoinOurTeam'

const Team = () => {
    return (
        <>
            <AboutTeam />
            <Teams />
            <JoinOurTeam />
        </>

    )
}

export default Team