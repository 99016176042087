import React from 'react'
import './Teams.scss'
import ProfileCard from './ProfileCard'
import Jonathan from "../../assets/images/team/Jonathan.jpg"
import Rea from '../../assets/images/team/Rea.jpg'
import Molay from '../../assets/images/team/Molay.jpg'
import Linn from '../../assets/images/team/Linn.jpg'
import Frank from '../../assets/images/team/Frank.jpg'
import Lorenzo from '../../assets/images/team/Lorenzo.jpg'
import {Col, Container, Row} from "react-bootstrap";
const Teams = () => {

    const TeamsData = [
        {
            teamName: 'The Team',
            teamData: [
                {
                    name: 'Jonathan Tjoa Algreen',
                    title: 'Founding Partner',
                    company: 'and Chief Executive Officer',
                    url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
                    image: Jonathan

                },
                {
                    name: 'Rea Tjoa Algreen',
                    title: 'Founding Partner',
                    company: 'and Chief Sustainability Officer',
                    url: 'https://www.linkedin.com/in/rea-tjoa-algreen-82b50959/',
                    image: Rea
                },
                {
                    name: 'Molay Ghosh',
                    title: 'Partner',
                    company: false,
                    url: 'https://www.linkedin.com/in/molay-ghosh-a74a7473/',
                    image: Molay
                },
                {
                    name: 'Linn Hermansson',
                    title: 'Associate - Funding & Investor Relations',
                    company: false,
                    url: 'https://www.linkedin.com/in/linn-hermansson-5421661a0/',
                    image: Linn
                },
                {
                    name: 'Frank Chellew',
                    title: 'Principle - Funding & Investor Relations',
                    company: false,
                    url: 'https://www.linkedin.com/in/frank-chellew-569584155/',
                    image: Frank
                },
                {
                    name: 'Lorenzo Petrantonio',
                    title: 'Senior M&A Analyst',
                    company: false,
                    url: 'https://www.linkedin.com/in/lorenzo-petrantonio-58478411a/',
                    image: Lorenzo
                }
            ]
        },
        // {
        //     teamName: 'The Advisors',
        //     teamData: [
        //         {
        //             name: 'Jonathan Tjoa Algreen',
        //             title: 'Founder & CEO',
        //             company: 'of IBIG and 1 People',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Jonathan Tjoa Algreen',
        //             title: 'Founder & CEO',
        //             company: 'of IBIG and 1 People',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Jonathan Tjoa Algreen',
        //             title: 'Founder & CEO',
        //             company: 'of IBIG and 1 People',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Rea Tjoa Algreen',
        //             title: 'CSO',
        //             company: 'of 1 People',
        //             url: 'https://www.linkedin.com/in/rea-tjoa-algreen-82b50959/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //         }
        //     ]
        // },
        // {
        //     teamName: 'The Group Company Representatives',
        //     teamData: [
        //         {
        //             name: 'Jesper Kring',
        //             title: 'CEO',
        //             company: 'of Valified',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //         },
        //         {
        //             name: 'Jakob lage Hansen',
        //             title: 'CEO',
        //             company: 'of Doland',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Svend Wennick',
        //             title: 'CEO',
        //             company: 'of Wennick-Lefevre',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Frank Arrigazzi',
        //             title: 'CEO',
        //             company: 'of Love Nature Superfoods',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //         },
        //         {
        //             name: 'Charsten Christensen',
        //             title: 'Chairman',
        //             company: 'of Nordic Impact Bridge',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Jonathan Tjoa Algreen',
        //             title: 'Founder & CEO',
        //             company: 'of IBIG and 1 People',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Jonathan Tjoa Algreen',
        //             title: 'Founder & CEO',
        //             company: 'of IBIG and 1 People',
        //             url: 'https://www.linkedin.com/in/jonathan-tjoa-algreen/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //
        //         },
        //         {
        //             name: 'Rea Tjoa Algreen',
        //             title: 'CSO',
        //             company: 'of 1 People',
        //             url: 'https://www.linkedin.com/in/rea-tjoa-algreen-82b50959/',
        //             image: 'https://indiashippingnews.com/wp-content/uploads/2021/06/Absar-Pic_Fotor.jpg'
        //         },
        //     ]
        // }
    ]

    return (
        <>
            <Container className='teams'>
                {
                    TeamsData.map((team, index) => (
                        <Row key={index}>
                            <Col>
                                <div className='team-name pb-5'>{team.teamName}</div>
                                <Row className='profileCards'>
                                    {
                                        team.teamData.map((profile, index) => (
                                            <ProfileCard profile={profile} key={index} />
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    ))
                }
            </Container>
        </>
    )
}

export default Teams;
