import React from 'react'
import OpenroleContent from './OpenroleContent'
import {Container} from "react-bootstrap";

export default function OpenRole() {
    return (
        <Container fluid={true}>
            <OpenroleContent/>
        </Container>
    )
}
