import "./Vision.scss";
import img3 from './pexels-cottonbro-studio-10679208 1.png'
import {Col, Container, Row} from "react-bootstrap";

const VisionCardRight = () => {
    return (
        <Container fluid={true} className={'vision py-5'}>
            <Container className={'py-lg-5 mw-100'}>
                <Row>
                    <Col lg={6} className={'d-none d-sm-block'}>
                        <div className="vision-img">
                            <img
                                className="w-75 mw-100 rounded-3 float-lg-start"
                                alt="abc"
                                src={img3}
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="vision-text pb-5">
                            <div className="font-soria largePlainText vision-text-head">We are not a traditional VC</div>
                            <p className="smallPlainText">We are building a global conglomerate of impact driven deeply embedded businesses across industries that changes the way business is done with a mission of changing the world for the better of all people and the planet.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default VisionCardRight;
