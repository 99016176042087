import React, {useEffect, useRef} from 'react'
import {Col, Container, Row, Tabs, Tab} from "react-bootstrap";
import FundingForm from "./FundingForm";
import SellForm from "./SellForm";

export default function PitchForm(props) {

    const pitchForm = useRef();

    const focusForm = () => {
        console.log(pitchForm.current);

        // window.scrollTo({
        //     top: 10000,
        //     left: 0,
        //     behavior: "smooth",
        // });
        pitchForm.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const key = props.type;

    console.log('KEY::', key);

    useEffect(() => {
        setTimeout(()=>{
            if (typeof key != 'undefined') {
                focusForm();
            }
        }, 1000)
        // code to run after render goes here
    });

    return (
        <Row style={{backgroundColor: '#F6F0DE'}}>
            <Col lg={12}>
                <Container className={'py-5'}>
                    <div className={'pitch-forms mx-auto'}>
                        <h2 ref={pitchForm} className={'secondary-title my-5 text-center'}>Pitch Us</h2>
                        <Tabs justify={true} id="pitch-form"
                              defaultActiveKey={'funding'}
                              activeKey={key}
                              //onSelect={(k) => setKey(k)}
                              className="my-3 justify-content-center">
                            <Tab eventKey="funding" title="Looking for Funding?">
                                <FundingForm />
                            </Tab>
                            <Tab eventKey="selling" title="Sell Your Business??">
                                <SellForm />
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </Col>
        </Row>
    )
}
