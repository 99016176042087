import React from 'react'
import {Button, Col, Row} from "react-bootstrap";

export default function SellForm() {

    return (
        <form>
            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="email" className="form-control" id="company_name" placeholder="Company Name *"
                               required={true}/>
                        <label htmlFor="company_name">Company Name *</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <textarea className='form-control' id='description'
                                  name='description'
                                  placeholder='Elevator Pitch, why should we invest in your company? *'
                                  required={true}></textarea>
                        <label htmlFor="description">Elevator Pitch, why should we invest in your company? *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="city" placeholder="City *" required={true}/>
                        <label htmlFor="city">City *</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="state" placeholder="State/County *"
                               required={true}/>
                        <label htmlFor="state">State/County *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="company_structure"
                               placeholder="Company Structure"/>
                        <label htmlFor="company_structure">Company Structure</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                                                <textarea className='form-control' id='about_ibig'
                                                          name='about_ibig'
                                                          placeholder='How did you know about iBig? *'
                                                          required={true}></textarea>
                        <label htmlFor="about_ibig">How did you hear about iBig? *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="first_name" placeholder="First Name *"
                               required={true}/>
                        <label htmlFor="first_name">First Name *</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="last_name" placeholder="Last Name *"
                               required={true}/>
                        <label htmlFor="last_name">Last Name *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="email" className="form-control" id="email" placeholder="Email Address *"
                               required={true}/>
                        <label htmlFor="email">Email Address *</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="tel" className="form-control" id="phone" placeholder="Phone Number *"
                               required={true}/>
                        <label htmlFor="phone">Phone Number *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="amount"
                               placeholder="Amount *" required={true}/>
                        <label htmlFor="amount">Amount *</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="valuation" placeholder="Valuation"
                               required={false}/>
                        <label htmlFor="valuation">Valuation</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="revenue_second_last" placeholder="2022 Revenue"
                               required={false}/>
                        <label htmlFor="revenue_second_last">2022 Revenue</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="revenue_last" placeholder="2023 Revenue"
                               required={false}/>
                        <label htmlFor="revenue_last">2023 Revenue</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="company_website" placeholder="Company Website *"
                               required={true}/>
                        <label htmlFor="company_website">Company Website *</label>
                    </div>
                </Col>
                <Col className={'mb-2'}>
                    <div className={'form-floating'}>
                        <input type="text" className="form-control" id="revenue_last" placeholder="Industry"
                               required={false}/>
                        <label htmlFor="revenue_last">Industry</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-4'}>
                    <div className={'form-floating'}>
                        <input type='url' className='form-control' id='pitch_url'
                               name='pitch_url' placeholder='Pitch Deck Link *' required={true}></input>
                        <label htmlFor="pitch_url">Pitch Deck Link *</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={'mb-5'}>
                    <label htmlFor="pitch_doc" className="form-label">Please attach your pitch deck</label>
                    <input type='file' className='form-control' id='pitch_doc'
                           name='pitch_doc' placeholder='Please attach your pitch deck' required={false}></input>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button size={'sm'} className={'rounded-pill my-4 w-100'}>Submit</Button>
                </Col>
            </Row>
        </form>
    );
};
