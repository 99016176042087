import React from 'react'
import "./WhoWeAre.scss"
import {Accordion, AccordionCollapse, Container} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";

export default function WhoWeAre() {
    return (
        <>
            <Container fluid={true} style={{
                background: "#ECE4CE"
            }}>
                <Container className={'d-flex py-5 who-we-are-banner'}>
                    <div className={'align-self-center d-flex flex-column align-items-center text-center'}>
                        <h2 className={'font-futura'} style={{
                            fontSize: '1.5rem',
                            fontWeight: '600',
                            color: 'rgba(29, 28, 27, 0.50)',
                        }}>ABOUT</h2>
                        <h1 className={'font-soria'} style={{
                            fontSize: "6rem",
                            fontWeight: "100"
                        }}>WHO WE ARE</h1>
                        <p className={'who-we-are-banner-text'} style={{
                            fontSize: '1.75rem',
                        }}>We are Impact Business Investment Group,a leading investment firm dedicated to supporting and
                            catalysing the growth of impactful businesses that create positive change in society and the
                            environment.</p>
                    </div>
                </Container>
            </Container>
            <Container className={'who-we-are-container py-5'} fluid={true}>
                <Container className={'my-5'}>
                    <h1 className={'py-3'}>Why we built iBig</h1>
                    <p>At Impact Business Investment Group, we believe that business has the power to drive meaningful
                        social and environmental impact while delivering financial returns. Our mission is to identify,
                        invest in, and nurture purpose-driven companies that are committed to addressing the world's
                        most pressing challenges. What sets us apart is our unwavering commitment to impact. We
                        carefully select and partner with companies that demonstrate a clear social or environmental
                        mission at their core. From renewable energy and sustainable agriculture to healthcare
                        innovation and education technology, we invest in a diverse range of sectors that have the
                        potential to make a real difference. Our team of experienced investment professionals combines
                        financial acumen with a deep understanding of impact-driven businesses. We leverage our
                        expertise and network to provide not just capital but also strategic guidance and operational
                        support to our portfolio companies. We actively engage with our investees to help them scale
                        their impact and achieve long-term success.</p>
                    <p>Transparency, integrity, and collaboration are the cornerstones of our approach. We prioritize
                        open and honest communication with our investors, partners, and portfolio companies. We seek
                        meaningful partnerships and collaborations with like-minded organizations, recognizing that
                        collective efforts are crucial in driving lasting change.</p>
                    <p>Join us on our journey to create a better future through impact investing. Whether you are an
                        investor looking to align your capital with your values or an entrepreneur seeking support and
                        funding for your impactful venture, we invite you to explore how Impact Business Investment
                        Group can be your trusted partner.</p>

                    <h1 className={'py-3'}>How we work with startups</h1>
                    <p>Our overall focus is on creating a positive impact by investing in businesses that drive
                        meaningful change. While investing in startups there are also many more perspectives to consider
                        and we always work closely with the company to give the best foundations towards success.</p>
                    <p>Once an investment is made in a startup, we shift our focus to implementing the right strategy,
                        processes, structures, and organizational culture. We work closely with the invested company to
                        refine their strategic direction, optimize their operational processes, establish effective
                        structures, and cultivate a strong organizational culture. By providing strategic guidance,
                        mentorship, and operational support, we help the company navigate challenges and unlock their
                        full potential.</p>
                </Container>
            </Container>
            <Container className={'py-5 d-none'} fluid={true} style={{
                backgroundColor: "#F6F0DE",
            }}>
                <Container className={'my-5 w-75'}>
                    <Accordion flush={true} id={"who-we-are-accordion"} className={'w-100'}>
                        <AccordionItem eventKey={'objectives'}>
                            <AccordionHeader>
                                Our Objectives
                            </AccordionHeader>
                            <AccordionCollapse eventKey={'objectives'}>
                                <AccordionBody>
                                    <p>Lorel Ipsum</p>
                                </AccordionBody>
                            </AccordionCollapse>
                        </AccordionItem>

                        <AccordionItem eventKey={'offers'}>
                            <AccordionHeader>
                                What We Offer
                            </AccordionHeader>
                            <AccordionCollapse eventKey={'offers'}>
                                <AccordionBody>
                                    <p>Lorel Ipsum</p>
                                </AccordionBody>
                            </AccordionCollapse>
                        </AccordionItem>

                        <AccordionItem eventKey={'investments'}>
                            <AccordionHeader>
                                Our Investments
                            </AccordionHeader>
                            <AccordionCollapse eventKey={'investments'}>
                                <AccordionBody>
                                    <p>Lorel Ipsum</p>
                                </AccordionBody>
                            </AccordionCollapse>
                        </AccordionItem>

                        <AccordionItem eventKey={'partners'}>
                            <AccordionHeader>
                                Our Partners
                            </AccordionHeader>
                            <AccordionCollapse eventKey={'partners'}>
                                <AccordionBody>
                                    <p>Lorel Ipsum</p>
                                </AccordionBody>
                            </AccordionCollapse>
                        </AccordionItem>
                    </Accordion>
                </Container>
            </Container>
        </>
    )
}
