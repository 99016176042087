import React from 'react'
import PressReleasesCard from './PressReleasesCard'
import img1 from "./Frame9.png"

const PressReleases = () => {

    const pressReleasesData = [
        {
            image: img1,
            data: 'February 20, 2023',
            heading: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            data: 'February 20, 2023',
            heading: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            data: 'February 20, 2023',
            heading: 'Lorem ipsum dolor sit amet consectetur.',
        }
    ]
    return (
        <>
            <div className='pressReleases'>

                <p className='pressReleases-title'> Press Releases</p>

                {
                    pressReleasesData.map( (pressReleasesCard) => (
                        <PressReleasesCard pressReleasesCard={pressReleasesCard}/>
                    ))
                }

            </div>
        </>
    )
}

export default PressReleases
