import "./CareerPoster.scss";
import img1 from "./Career Page_814x890px (1).svg"
import {Button, Col, Container, Row} from "react-bootstrap";

const CareerPoster = () => {
    return (
        <>
            <div className="careerPoster">
                <Container>
                    <Row className={'flex-column flex-lg-row'}>
                        <Col className="careerPoster-left">
                            <img className={'rounded-4'} alt="" src={img1} />
                        </Col>

                        <Col className="careerPoster-right">
                            <div className="careerPoster-right-head">
                                <div className="about-us1">CAREER</div>
                                <div className="about-us2" style={{ textTransform: 'uppercase', fontSize: '4rem', marginBottom: '20px' }}>Join our team</div>
                            </div>

                            <div className="careerPoster-plainText" style={{ marginBottom: "50px" }}>
                                <div>
                                    If you are passionate about making a difference, we invite you to
                                    explore our current job openings and discover how you can
                                    contribute your skills and expertise to our mission.
                                </div>
                                <div>
                                    Join our team and be part of a community that is dedicated to driving positive change and creating a better future.
                                </div>
                            </div>

                            <Button className={'my-4 rounded-pill'}  size={'lg'} variant={'primary'} href={'/open-roles/'} >Jump to Open Role</Button>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CareerPoster;
