import React from 'react'
import ContactContent from './ContactContent'

const Contact = () => {
    return (
        <>
        <ContactContent />
        </>
    )
}

export default Contact