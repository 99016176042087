import React from 'react'

const NewsPoster = () => {
    return (
        <>
            <div className='newsPoster'>
                <div className='about-us1'>PRESS</div>
                <div className='about-us2' style={{ marginBottom: '40px' }}>NEWSROOM</div>
                <div className='smallPlainText' style={{ textAlign: 'center' }}>
                    Discover how our investments are creating positive change in various sectors, including renewable energy, sustainable agriculture, education, healthcare, and more. From exciting new partnerships and innovative solutions to advancements in impact measurement and reporting, we share the stories that showcase the transformative power of purpose-driven businesses.
                </div>
            </div>
        </>
    )
}

export default NewsPoster