import React from 'react'
import {Col, Container} from "react-bootstrap";

const AboutUsBody = () => {
    return (
        <Col className='aboutUsBody'>
            <Container>
                <div className="aboutUsBody-child">
                    <div>
                        <p>
                            Impact Business Investment Group (IBIG) was founded in 2018 with a clear objective: to transform the world through impactful investments. As an impact-driven investment group, IBIG is solely founded and funded by Algreen Capital, a Copenhagen-based Family Office that operates globally.
                        </p>
                        <p>
                            Our group leverages a strong network of entrepreneurs and investors, ranging from business angels to institutional investors, to facilitate global scaling and achieve a higher success rate in our investments. At IBIG, we are driven by our commitment to positive impact, entrepreneurial spirit, and delivering high returns on investment. These goals are made possible by employing a specialized framework that involves identifying specific characteristics for successful startups and implementing the necessary processes and structures for successful scaling. To ensure that we give the best opportunities for the companies we invest in, they will go through Fair Business Growth where extensive support and valuable knowledge will help bring each and every company to its fullest potential.
                        </p>
                    </div>
                </div>

                <div className="aboutUsBody-child">
                    <h1 className={'font-soria'}>From Ideas to Exit</h1>
                    <div>
                        <p>
                            We increase the chances of success by employing a structured approach to our investments and maintaining close involvement in the strategy and development of each company we invest in. Our overall focus is on creating a positive impact, and we achieve this through a structured model that includes the following steps.
                        </p>
                    </div>

                    <ul className='aboutUsBody-ul'>
                        <li className={'mb-3'}>
                            Identifying the right business cases and ideas. (pre-investment)
                        </li>
                        <li className={'mb-3'}>
                            Identifying the right entrepreneurs and teams. (pre-investment)
                        </li>
                        <li className={'mb-3'}>
                            Identifying specific characteristics and potential for scaling. (pre-investment)
                        </li>
                        <li className={'mb-3'}>
                            Implementing right strategy, processes, structures and organizational culture. (post-investment)
                        </li>
                        <li className={'mb-3'}>
                            Continuously follow-up and support on strategy and execution (post-investment until exit)
                        </li>
                    </ul>
                </div>

                <div className="aboutUsBody-child">

                    <h1 className={'font-soria'}>Investment focus</h1>

                    <div>
                        <p>
                            We invest in impact driven businesses that have a scalable and highly profitable potential. We are close to the leadership and strategy of each company and we are driven to develop and scale the companies we invest in. We do not invest in non-scalable, non-impact driven and solely locally oriented companies.
                        </p>
                    </div>
                    <div>
                        <p>
                            Our approach is driven by our extensive expertise in building startups from the ground up, taking them to a global scale, and ensuring their success. Throughout our history, we have successfully supported numerous companies in various sectors, enabling them to achieve significant growth and positive impact.
                        </p>
                    </div>
                    <div>
                        <p>
                            Our primary focus is the seed stage of startups, with some limited involvement in pre-Seed and later Series stages, as well as potential IPOs. The sweet spot for our approach lies in early Seed investments, aiming for successful exits at the Series A stage. Our expected return when exiting at Series A is 3-5x.
                        </p>
                    </div>
                    <div>
                        <p>
                            Our investments typically range from a ticket size of $25,000 to $2 mio. and we often do follow-up investments at later rounds.
                        </p>
                    </div>
                </div>
            </Container>

        </Col >
    )
}

export default AboutUsBody
