import React from "react";
import "./Portfolio.scss";
import PortfolioCard from "./PortfolioCard";
import {Container} from "react-bootstrap";

const Portfolio = () => {
  const portfolioCardArray = [
    {
      title: "1 People",
      category: "Consumer | Denmark",
      stake: '98.44%',
      roi: '245%',
      status: 'Series A',
      highlight: 'green',
      logo: '1People.png',
      url: 'https://1people.com',
      details:
        "1 People is a Danish sustainable luxury brand that designs effortlessly timeless looks for conscious-minded women so that they can stay stylish in an ethical, high-quality fashion.",
    },
    {
      title: "FairMedia",
      category: "Media | USA",
      stake: '100%',
      roi: '900%',
      status: 'Pre-Seed',
      highlight: 'green',
      logo: 'FairMedia.png',
      url: false,
      details:
          "FairMedia stands as a conscious media center committed to promoting impact driven lifestyle, business and entrepreneurs. With a dedicated focus on responsible journalism, FairMedia seeks to create a media environment that fosters awareness, inclusivity, and positive social impact. Through its conscientious approach, FairMedia strives to elevate the standard of media content, delivering narratives that resonate with authenticity and contribute to a more enlightened and empathetic society.",
    },
    {
      title: "Wennick-Lefèvre",
      category: "Natural and Ethical Gem Stones | Denmark",
      stake: '50%',
      roi: '50%',
      status: 'Organic Growth',
      highlight: 'green',
      logo: 'WL.png',
      url: 'https://www.wennicklefevre.com/',
      details:
          "At Wennick–Lefèvre, we don’t believe in treating gemstones. For us, a treated gemstone is as inauthentic as gold plated jewellery. Only a natural gemstone is a true representation of our planet – pure, beautiful and complex. The gemstones come from Sri Lanka, the men and women working at Sanjeewa’s workshop are in their mid twenties to late fourties, the master cutters are older. They come from the area around the small village of Kuruwita, near Rathnapura, where this expertise has been accumulated over centuries.",
    },
    {
      title: "DoLand",
      category: "Investment Platform, Tech | Denmark",
      stake: '24%',
      roi: '182%',
      status: 'Seed',
      highlight: 'green',
      logo: 'DoLand.png',
      url: 'https://thisisdoland.com/en/',
      details:
          "At DoLand the investments define the future, they want to create a better future. But instead of waiting for the perfect companies and investment funds, they are committed to moving money towards more sustainable solutions that drive positive change.",
    },
    {
      title: "Valified",
      category: "Fintech | Denmark",
      stake: '19.57%',
      roi: '17%',
      status: 'Seed',
      highlight: 'green',
      logo: 'Valified.png',
      url: 'https://www.valified.com/',
      details:
          "Valified is a digital platform that makes it easy to record, measure, report, share and benchmark companies' work on transformation. Valified develops smart, data-driven technologies and services that accelerate progress towards greener and more sustainable businesses across value chains.",
    },
    {
      title: "FairShop.com",
      category: "E-Commerce, Tech and Logistics | USA",
      stake: '100%',
      roi: '2900%',
      status: 'Pre-Seed',
      highlight: 'green',
      logo: 'FairShop.png',
      url: false,
      details:
          "Farishop.com is gearing up to be a game-changer in e-commerce. Our focus is on offering sustainable products to global consumers, an automated global storage & logistics with fast and cheap handling and shipping. With the world increasingly embracing sustainability, we're entering the market in 2023 at the perfect time. Farishop.com isn't just an online store; it's a commitment to conscious consumption and sustainability. Join us on this exciting journey!",
    },
    {
      title: "Artelize",
      category: "Tech, Social Network, and Marketplace | Denmark",
      stake: '2.50%',
      roi: '100%',
      status: 'Seed',
      highlight: 'green',
      logo: 'Artelize.png',
      url: 'https://artelize.com/',
      details:
          "Artelize is an AI-powered data aggregation creating a one-stop discovery platform for the world of performing arts. Artelize’s AI solves this by mapping and connecting the entire industry. All events and tickets, all performers and arts professionals, arts organisations, musical works, recordings, media, news, reviews etc.",
    },
    {
      title: "Fair Business Growth",
      category: "Business Service | USA",
      stake: '100%',
      roi: '1900%',
      status: 'Pre-Seed',
      highlight: 'green',
      logo: 'FairBusinessGrowth.png',
      url: 'https://fairbusinessgrowth.com',
      details:
          "Fair Business Growth focus on bridging the gap between dreams and reality for companies. A marketplace and agency that is dedicated to helping companies achieve their aspirations through comprehensive 360-degree services. They are offering solutions covering sales, performance ads, organic content, accounting, PR, strategic market analysis, tech, funding, and manufacturing.",
    },
    {
      title: "Love Nature Superfoods",
      category: "Food, Ingredients | United Kingdom",
      stake: '52.5%',
      roi: '108%',
      status: 'Made an Exit',
      highlight: 'yellow',
      logo: 'LoveNature.png',
      url: 'https://www.lovenaturesuperfoods.com/',
      details:
          "Inspired by the power of organic nutrition, lovenature have a mission to shake up the industry and to make organic ingredients more widely available and more affordable in the market. In Lovenature Superfoods, they are striving to create an organic, responsibly sourced business that combats an industry dominated by pesticides and herbicides.",
    },
    {
      title: "Concordium",
      category: "Blockchain, Crypto | Switzerland",
      stake: 'CCD',
      roi: '-84%',
      status: 'Made an Exit',
      highlight: 'yellow',
      logo: 'Concordium.png',
      url: 'https://www.concordium.com/',
      details:
          "Concordium is a blockchain with a smart contract layer for business with focus on low and stable transaction costs and ID-verified participation. Any person or company with an identity can get an account on Concordium.",
    },
    // {
    //   title: "Business For Planet",
    //   category: "Educational | Denmark",
    //   stake: '$000',
    //   roi: '$000',
    //   status: 'Pre-Seed',
    //   highlight: 'green',
    //   details:
    //     "Business for Planet provides complimentary training and mentoring of entrepreneurs and people who want to become one from all over the world. Alongside the mentoring, BFP is also a safe place to connect and network internationally with a diversified range of impact driven entrepreneurs. ",
    // },
    // {
    //   title: "Nordic Bridge Impact",
    //   category: "Service | Denmark",
    //   stake: '$000',
    //   roi: '$000',
    //   madeAnExit: 1,
    //   details:
    //     "Nordic Impact Bridge is based on a philosophy that co-creation is the best way to create market standards. That's why we invite all relevant partners on board, so that together we can create a perfect match between the most talented and promising small and medium-sized enterprises in the Nordic region and their need for capital and skills.",
    // },
  ];

  //portfolioCardArray = [portfolioCardArray[0]];

  return (
    <Container fluid={true}>
      <Container className={'mb-5'}>
        <div className="about-us2 mw-100 text-uppercase text-center my-5 py-5">
          OUR <br /> Portfolio
        </div>
        <div className="portfolio-header w-75 mx-auto my-5">
          <p>
            The companies inside Impact Business Investment Group all have different focuses and serve different needs. But they all have one thing in common - they aim to make the world a better place through their business ideas and initiatives.
          </p>
        </div>
      </Container>
      <Container className={'mt-5 px-5 portfolio-items'}>
        {portfolioCardArray.map((portfolioCard, index) => (
            <PortfolioCard portfolioCard={portfolioCard} key={index} />
        ))}
      </Container>
    </Container>
  );
};

export default Portfolio;
