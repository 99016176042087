import React from 'react'

export default function MediaKit() {
  return (
    <div style={{
        backgroundColor: "#ECE4CE",
        width: "1519px",
        height: "673px",
        display: "flex",
        alignItems:"center",
        justifyContent:"space-around"
    }}>
        <div style={{
            color: "#1E1E1E",
            textAlign: "center",
            fontFamily: "SoriaFont",
            fontSize: "4rem",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "110px",
            letterSpacing:"normal"
        }}>MEDIA  KIT</div>
        <div>
            <h2 style={{
              fontWeight:"100",
              fontFamily:'Comfortaa',
              fontSize:'27px',
              width:'79%'

            }}>Download iBig exclusive Media Kit</h2>
            <button style={{
                borderRadius: "30px",
                color: "white",
                backgroundColor: "black",
                fontFamily:'Comfortaa',
                padding: "11px",
                width: "35%"
            }}>Download</button>
        </div>
    </div>
  )
}
