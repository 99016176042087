import React from 'react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const PressReleasesCard = ({ pressReleasesCard }) => {
    return (
        <>
            <div className='pressReleasesCard'>
                <img className='pressReleasesCard-img' src={pressReleasesCard.image} alt='' width='100%' />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CalendarTodayIcon />
                    <span className={'ms-2'}>{pressReleasesCard.data}</span>
                </div>
                <div className='pressReleasesCard-heading'>{pressReleasesCard.heading}</div>
            </div>
        </>
    )
}

export default PressReleasesCard
