import React from 'react'
import {Button, Card, CardImg, Col, Row} from "react-bootstrap";

const PortfolioCard = ({ portfolioCard }) => {
    let portfolioLogo = require('../../assets/logos/'+portfolioCard.logo);

    return (
        <Card className='portfolio-item py-5 px-4' border={''}>
            <Row className="g-0">
                <Col lg={3} className={'p-3 d-flex flex-column justify-content-center align-items-center'}>
                    <CardImg variant={'left'} src={portfolioLogo}></CardImg>
                </Col>
                <Col lg={6}>
                    <Card.Body>
                        <Card.Title className={'mb-4'}><a target={'_blank'} rel={'noreferrer'}  href={portfolioCard.url ? portfolioCard.url : '#'}>{portfolioCard.title}</a></Card.Title>
                        <Card.Subtitle className={'mb-4'}>{portfolioCard.category}</Card.Subtitle>
                        <Card.Subtitle className={'mb-4'}>
                            <Row>
                                <Col lg={6}>
                                    <Row>
                                        <Col>Our Stake: <span>{portfolioCard.stake}</span></Col>
                                        {/*<Col>Our ROI: <span>{portfolioCard.roi}</span></Col>*/}
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Subtitle>
                        <Card.Text className={'lh-lg'}>
                            {portfolioCard.details}
                        </Card.Text>
                    </Card.Body>
                </Col>
                <Col lg={3} className={'py-3 d-flex flex-column justify-content-lg-center align-items-end align-items-lg-center'}>
                    <div className={'portfolio-status'}>
                        <Button size={'sm'} className={'rounded-pill'} variant={portfolioCard.highlight}>{portfolioCard.status}</Button>
                    </div>
                </Col>
            </Row>

        </Card>
    )
}

export default PortfolioCard
