import React from 'react'
import {Button} from "react-bootstrap";


const MakeYourMark = () => {
    return (
        <>
            <div className='makeYourMark'>
                <div className='about-us2' style={{ marginBottom: '20px' }}>
                    Make Your Mark
                </div>

                <div className='careerPoster-plainText'>
                    <div style={{ fontSize: '22px', textAlign: 'center' }}>
                        IBIG is trusted by thousands of startup employees as the leading provider of equity planning and financing. We’re the first to provide a proprietary suite of equity planning tools, 1:1 guidance with licensed equity strategists,  and a set of financing products that enable employees to own a stake in the company they helped build.
                    </div>
                </div>

                <div className='makeYourMark-btns flex-column flex-lg-row'>
                    <Button size={"sm"} className={'rounded-pill my-4'} href={'/who-we-are/'}>Read Our Story</Button>
                    <Button size={"sm"} className={'rounded-pill my-4'} href={'/contact/'}>Book a Call</Button>
                </div>
            </div>
        </>
    )
}

export default MakeYourMark
