import React from 'react'
import {Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const ProfileCard = ({ profile }) => {
    return (
        <>
            <Col lg={3} className='profileCard'>
                <img className='profileCard-img' src={profile.image} alt='' />
                <div className='profileCard-name'><a rel="noreferrer" target={'_blank'} href={profile.url}>{profile.name}</a></div>
                <div className='profileCard-post'>{profile.title}&nbsp;</div>
                <div className='profileCard-info'>{profile.company}&nbsp;</div>

                {profile.url ?
                    <div className='profileCard-url'> <a rel={'noreferrer'} target={'_blank'} href={profile.url}><FontAwesomeIcon icon={icon({name: 'linkedin', style: 'brands'})} /></a></div> : ''
                }
            </Col>
        </>
    )
}

export default ProfileCard
