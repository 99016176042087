import React from 'react'
import img2 from './pexels-scott-webb-532573.jpg'
import img3 from "./Image_20231215_104853_380.jpg";
import {Button, Col, Container, Row} from "react-bootstrap";


function PitchBottom() {
    return (
        <Container>
            <Row className="pitch-bottom flex-column-reverse flex-lg-row">
                <Col lg={6} className={'py-5'}>
                    <img
                        className="rounded-4"
                        alt=""
                        src={img2}
                    />
                </Col>
                <Col lg={6} className="py-5 d-flex justify-content-center">
                    <div className={'align-self-center'}>
                        <h2 className={'secondary-title mb-5'}>
                            Thanks for <br></br>
                            your interest<br></br>
                            in iBig.
                        </h2>

                        <p>
                            If your company meets our criteria, please fill <br></br>
                            out this form so our team can learn about <br>
                        </br> your company.
                        </p>
                        <p style={{
                            fontSize: '1.25rem',
                            fontWeight: '400',
                            fontFamily: 'Comfortaa',
                            lineHeight: '1.5rem',
                        }}>
                            Please download our Deck Template if you <br></br>
                            are looking for guidance.
                        </p>

                        <p style={{
                            fontSize: '1.25rem',
                            fontWeight: '400',
                            fontFamily: 'Comfortaa',
                            lineHeight: '1.5rem',
                        }}>
                            Please allow us two to three weeks to get back to you.
                        </p>

                        <Button size={'sm'} href={'#'} className={'rounded-pill my-5'}>Download Deck Template</Button>
                    </div>
                </Col>
            </Row>
            <Row className="pitch-bottom">
                <Col lg={6} className="py-5 d-flex">
                    <div className={'align-self-center'}>
                        <h2 className={'secondary-title mb-5'}>Partner with us - We build strong networks</h2>

                        <p>
                            We seek out companies that align with our values, possess
                            strong growth potential, and demonstrate a clear
                            commitment to social and environmental responsibility.
                        </p>
                        <p>
                            Connect with us to explore how you can become an
                            integral part of our mission. Whether you are an individual investor,
                            a VC, a family office, or an institutional investor,
                            we offer tailored investment solutions designed
                            to align your financial goals with your values.
                        </p>
                        <p>
                            Join our community of impact investors and become part of a global movement that is driving
                            positive change. Together, let's create a more sustainable, equitable, and prosperous future
                            through the power of impact investing.
                        </p>

                        <Button href={'#funding'} size={'sm'} className={'rounded-pill my-5'}>Fill in the Form</Button>
                    </div>
                </Col>
                <Col lg={6} className="py-5">
                    <img
                        className="rounded-4"
                        alt=""
                        src={img3}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default PitchBottom
