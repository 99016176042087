import React from 'react'
import HomePoster from './HomePoster'
import VisionPoints from './VisionPoints'
import VisionCardLeft from './VisionCardLeft'
import VisionCardRight from './VisionCardRight'
import Subscribe from './Subscribe'

const Home = () => {
    return (
        <>
            <HomePoster />
            <VisionCardLeft />
            <VisionCardRight />
            <VisionPoints />
            <Subscribe />
        </>
    )
}

export default Home
