import React from 'react'
import './AboutTeam.scss'
import {Container, Row, Col} from "react-bootstrap";

const AboutTeam = () => {
    return (
        <>
            <Container className="aboutTeam">
                <Row>
                    <Col lg={12} className="my-5">
                        <div className={'aboutTeam-mainText'}>
                            <p>
                                At Impact Business Investment Group, our team is comprised of dedicated
                                professionals who are passionate about creating positive change through
                                impactful investments. We bring together a diverse range of expertise
                                and experience, united by our shared commitment to making a difference
                                in the world.
                            </p>
                        </div>
                    </Col>
                    <Col lg={12}  className="mb-5 justify-content-end">
                        <div className={'aboutTeam-plainText'}>
                            <p>
                                Jonathan Algreen is the visionary behind Impact Business Investment
                                Group. With a deep understanding of impact investing and a strong
                                belief in the power of business to drive positive change, Jonathan
                                founded the company to bridge the gap between finance and impact.
                                Their leadership guides the overall strategy and direction of the
                                firm, ensuring that our investments align with our mission and values.
                            </p>

                            <p>
                                Together, our team combines our skills, experience, and passion to
                                drive positive change through impact investing. We are dedicated to
                                building a portfolio of high-impact companies, supporting their
                                growth, and generating both financial returns and meaningful social
                                and environmental outcomes.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AboutTeam
