import React from 'react'
import ConImage from "./ConImage.png"
import "./Contact.scss"
import {Button, Col, Container, Form, Row} from "react-bootstrap";

export default function ContactContent() {
    return (
        <>
            <Container className={'contact-left'} style={{
                background: "#FFFCF4"
            }}>
                <Row className={'my-5'}>
                    <Col>
                        <h1 style={{
                            fontFamily: "SoriaFont",
                            fontWeight: '500',
                            fontSize: '3rem',
                        }}>Get In Touch</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className='Contact-leftside'>
                            <p>Phone</p>
                            <h5 className={'lh-lg'}>+46 730 7505 22</h5>
                        </div>
                        <div className='Contact-leftside'>
                            <p>Email</p>
                            <h5>info@ibig.vc</h5>
                        </div>
                        <div style={{
                            marginBottom: "120px"
                        }}>
                            <p>Address</p>
                            <h5>Copenhagen, Denmark</h5>
                        </div>
                    </Col>
                    <Col lg={7}>
                        <Form id={'contact-form'}>
                            <Row>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='first_name' id="first_name" placeholder='First Name'
                                               className='form-control' required></input>
                                        <label htmlFor="first_name">First Name</label>
                                    </div>
                                </Col>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='last_name' id="last_name" placeholder='Last Name'
                                               className='form-control' required></input>
                                        <label htmlFor="last_name">Last Name</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='email' name='email' id="email" placeholder='Email Address'
                                               className='form-control' required></input>
                                        <label htmlFor="email">Email Address</label>
                                    </div>
                                </Col>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='tel' name='phone' id="phone" placeholder='Phone Number'
                                               className='form-control' required></input>
                                        <label htmlFor="phone">Phone Number</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='address' id="address" placeholder='Address'
                                               className='form-control'></input>
                                        <label htmlFor="address">Address</label>
                                    </div>
                                </Col>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='city' id="city" placeholder='City'
                                               className='form-control'></input>
                                        <label htmlFor="city">City</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='country' id="country" placeholder='Country'
                                               className='form-control'></input>
                                        <label htmlFor="country">Country</label>
                                    </div>
                                </Col>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='company_name' id="company_name"
                                               placeholder='Company Name' className='form-control'></input>
                                        <label htmlFor="company_name">Company Name</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='company_url' id="company_url"
                                               placeholder='Company Name' className='form-control'></input>
                                        <label htmlFor="company_url">Company Name</label>
                                    </div>
                                </Col>
                                <Col lg={6} className={'mb-3'}>
                                    <div className={'form-floating'}>
                                        <input type='text' name='message' id="message" placeholder='Message'
                                               className='form-control'></input>
                                        <label htmlFor="message">Message</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'my-4'}>
                                    <Button type='submit' size={'sm rounded-pill'} variant={'primary'}>Send</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Row className={'d-none d-sm-block'}>
                <Col>
                    <img alt='contactimage' src={ConImage} style={{
                        height: "718px",
                        width: "100%"
                    }}/>
                </Col>
            </Row>
        </>
    )
}
