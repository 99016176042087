import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {Container} from "react-bootstrap";
import {Outlet} from "react-router-dom";

const Layout = () => {
    return(
        <Container fluid={true}>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </Container>
    )
}

export default Layout;
