import './OurValues.scss'
import React from 'react'
import img2 from "./Career Page_814x890px (2).svg"
import {Accordion, AccordionCollapse, Col, Container, Row} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";

const OurValues = () => {
    return (
        <div className="ourValues py-5">
            <Container>
                <div className="about-us1" style={{ textTransform: 'uppercase' }}>Our Values</div>
                <div className='about-us2' style={{ textTransform: 'uppercase', fontSize: '3rem', marginBottom: '3rem' }}>
                    foster a culture where our teammates can thrive, both personally and professionally
                </div>
                <Row>
                    <Col lg={6}>
                        <img className={'w-100'} src={img2} alt='abc' />
                    </Col>
                    <Col lg={6} className={'d-flex align-items-center py-5'}>

                        <Accordion flush={true} id={"careers"} className={'w-100'}>
                            <AccordionItem eventKey={'passion-for-impact'}>
                                <AccordionHeader>
                                        Passion for Impact
                                </AccordionHeader>
                                <AccordionCollapse eventKey={'passion-for-impact'}>
                                    <AccordionBody>
                                        <p>We value employees who are passionate about making a positive impact. We seek individuals who are deeply committed to creating social and environmental change through their work. We believe that passion is a driving force behind innovation, dedication and the ability to go above and beyond to drive meaningful outcomes.</p>
                                    </AccordionBody>
                                </AccordionCollapse>
                            </AccordionItem>

                            <AccordionItem eventKey={'collaboration'}>
                                <AccordionHeader>
                                        Collaboration and teamwork
                                </AccordionHeader>
                                <AccordionCollapse eventKey={'collaboration'}>
                                    <AccordionBody>
                                        <p>We believe in the power of collaboration and teamwork. We value employees who thrive in a collaborative environment, where divest perspectives are respected and valued. We encourage teamwork, open communication and sharing of ideas to foster a culture of creativity, learning and collective problem-solving.</p>
                                    </AccordionBody>
                                </AccordionCollapse>
                            </AccordionItem>

                            <AccordionItem eventKey={'learning'}>
                                <AccordionHeader>
                                        Learning and Growth
                                </AccordionHeader>
                                <AccordionCollapse eventKey={'learning'}>
                                    <AccordionBody>
                                        <p>We aim for a culture of learning and growth. We value employees who are eager to expand their knowledge, skills and expertise. We provide opportunities for professional development, whether through training programs or mentoring relationships. We encourage employees to take initiative in their personal and professional growth, supporting their aspirations and helping them to reach their full potential.</p>
                                    </AccordionBody>
                                </AccordionCollapse>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default OurValues
