import "./Header.scss";
import Navbar from 'react-bootstrap/Navbar'
import img1 from "./Vector.svg"
import "./Portfolio/Portfolio.js"
import {Container, Nav, NavbarBrand, NavItem, NavLink} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";


const Header = () => {
    return (
        <header>
            <Navbar expand={"lg"}>
                <Container>
                    <NavbarBrand href={'/'}><img className="logo" alt="" src={img1}/></NavbarBrand>
                    <NavbarToggle aria-controls={'navbarMain'} aria-expanded={false}></NavbarToggle>
                    <NavbarCollapse id={'navbarMain'}>
                        <Nav className="ms-auto">
                            <NavItem>
                                <NavLink href={'/portfolio/'}>Portfolio</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={'/team/'}>Team</NavLink>
                            </NavItem>
                            <NavItem className={'d-none'}>
                                <NavLink href={'/news/'}>News</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={'/pitch-us/'}>Pitch Us</NavLink>
                            </NavItem>
                        </Nav>
                    </NavbarCollapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
