import React from 'react';
import "./VisionPoints.css";
import Contact from "./Contact";
import {Button, Container} from "react-bootstrap";
import BannerDesktop from "./pexels-vlad-bagacian-1061623 1.png";
import BannerMobile from "./pexels-vlad-bagacian-1061623.png";

const VisionPoints = (prop) => {

    return (
        <>
            <Container fluid={true} className={'vision py-5 beige'}>
                <Container className="vision horizontal">
                    <div className="vision-head mx-auto">
                        <div style={{fontFamily:"SoriaFont"}} className="largePlainText vision-text-head">IBIG's current valuation insights on flourish investments, showcasing an impressive return on investment.</div>
                    </div>
                    <div className="vision-content">
                        <div className="contact">
                            <div className="contact-cards">
                                <div className={'score-card'}>
                                    <div className="largePlainText mb-3 ">$ +99m</div>
                                    <h2>IBIG's <br/>Total Valuation</h2>
                                </div>
                                <div className={'score-card'}>
                                    <div className="largePlainText mb-3">8</div>
                                    <h2>Current <br/>Investments</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <div className="home-poster">
                <img className={'d-none d-sm-block'}
                     alt="Home Banner"
                     src={BannerDesktop}
                />
                <img className={'d-block d-sm-none'}
                     alt="Home Banner"
                     src={BannerMobile}
                />
                <Container className={'home-banner-wrapper d-flex flex-column align-items-center h-100 justify-content-center'}>
                    <div className="home-poster-text align-self-center">
                        <h2 className={'secondary-title'}>We are confident in our ability to build successful startups and willing to take risk.</h2>
                    </div>
                </Container>
                <div className="homePoster-about-us-btn">
                    <Button size={'lg'} variant={'light'} href={'/who-we-are/'} className={'my-4 rounded-pill homePoster-about-us'}>Who We Are</Button>
                </div>
            </div>
            <Container fluid={true} className={'vision py-5'}>
                <Container className="vision horizontal">
                    <div className="vision-head mx-auto">
                        <div style={{fontFamily:"SoriaFont"}} className="largePlainText vision-text-head">Our strength is building and scaling companies from small and local to big and global.</div>
                    </div>
                    <div className="vision-content">
                        <Contact></Contact>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default VisionPoints;
