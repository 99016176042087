import React from 'react';
import img from "./Frame 29.png"
import "./OpenRole.css"
import img1 from './chevron_left.png'
import {Button, Col, Container, Row} from "react-bootstrap";


function OpenroleContent() {
    return (
        <Row className={''}>
            <Col lg={12}>
                <Container fluid={true} style={{backgroundColor: '#ECE4CE'}}>
                    <Container className={'py-5'}>
                        <Row className={''}>
                            <Col lg={6} className={'d-flex align-items-center'}>
                                <div className="ps-lg-5 justify-content-center info-container flex-column">
                                    <h2 className={''}>
                                        <a className={'text-uppercase font-futura text-decoration-none text-bg-transparent-grey fw-bold'} href={'/careers/'}>
                                            <img className={'me-3 mb-2'} src={img1} style={{
                                                width: '25px',
                                                height: '25px',
                                            }} alt={''} />
                                            Back to Career
                                        </a>
                                    </h2>
                                    <h1 className={'font-soria'} style={{
                                        color: '#1D1C1B',
                                        fontSize: '65px',
                                    }}>
                                        VIEW ALL <br/>
                                        OPEN ROLES
                                    </h1>


                                    <p className={'font-comfortaafont-comfortaa fs-4 lh-base w-75'}>
                                        When you join us, you become part of a
                                        collaborative and dynamic environment
                                        where innovation, creativity, and
                                        passion thrive. We encourage our team members to think big,
                                        challenge the status quo,
                                        and make a real difference for people and the planet.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={img} alt=""
                                     style={{marginLeft: '0px', marginTop: '56px'}}/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Col>
            <Col lg={12} className="open-role">
                <div className={'open-roles-heading w-100 text-center'}>
                    <Button size={'lg'} className={'rounded-pill my-5'}>All Roles</Button>
                </div>
                <div className={'open-roles-content w-100 text-center py-5'} style={{
                    backgroundColor: 'rgb(236, 228, 206)'
                }}>
                    <p>There are no open roles currently.</p>
                </div>
            </Col>
        </Row>
    )
}

export default OpenroleContent
