import React from 'react'
import img1 from './pexels-nataliya-vaitkevich-7173040.jpg'
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {Accordion, AccordionCollapse, Col, Container, Row} from "react-bootstrap";
import AccordionBody from "react-bootstrap/AccordionBody";


function PitchTop() {
  return (
    <Container>
       <Row className="pitch-top">
           <Col lg={6} className="my-5 pe-5">
               <h2 className={'secondary-title mb-5'}>
                   Our investment criteria has 5 primary focuses
               </h2>

               <p>Everything you need to know about our investment criteria
                   is outlined below. </p><p>Please submit the investment form if
               looking for an investment.</p>

               <Accordion flush={true} id={"pitch-us-accordion"} className={'w-100'}>
                   <AccordionItem eventKey={'commitment'}>
                       <AccordionHeader>
                           Commitment to ESG
                       </AccordionHeader>
                       <AccordionCollapse eventKey={'commitment'}>
                           <AccordionBody>
                               <p>At IBIG, we prioritize Environmental, Social, and Governance (ESG) criteria in all our
                                   investments. We believe in creating sustainable value and contributing positively to
                                   society and the environment. Our strong focus on ESG ensures that we are not just
                                   chasing returns, but also making a positive impact.</p>
                           </AccordionBody>
                       </AccordionCollapse>
                   </AccordionItem>

                   <AccordionItem eventKey={'stages'}>
                       <AccordionHeader>
                           Targeting Early Stages
                       </AccordionHeader>
                       <AccordionCollapse eventKey={'stages'}>
                           <AccordionBody>
                               <p>Our primary focus lies in Pre-seed and Seed stage investments, with selective
                                   participation in later Series stages and opportunities for IPOs and company
                                   acquisitions. We aim to identify and nurture startups from their nascent stages,
                                   guiding them towards growth and success.</p>
                           </AccordionBody>
                       </AccordionCollapse>
                   </AccordionItem>

                   <AccordionItem eventKey={'investments'}>
                       <AccordionHeader>
                           Strategic Investment Approach
                       </AccordionHeader>
                       <AccordionCollapse eventKey={'investments'}>
                           <AccordionBody>
                               <p>We prefer to take larger equity stakes when investing in third-party startups,
                                   particularly during the early seed stage. Our sweet spot is exiting at Series A with
                                   an expected return of 3-5x. This strategic approach ensures that we are deeply
                                   involved and committed to the success of the startups we invest in.</p>
                           </AccordionBody>
                       </AccordionCollapse>
                   </AccordionItem>

                   <AccordionItem eventKey={'ventures'}>
                       <AccordionHeader>
                           Majority-Owned Ventures
                       </AccordionHeader>
                       <AccordionCollapse eventKey={'ventures'}>
                           <AccordionBody>
                               <p>For companies that we have founded, operated, and hold a majority stake in, our goal
                                   is to grow them into profitable entities that give an ongoing return through
                                   dividends. We aim for substantial returns, and in certain cases, seek partial exits
                                   through Initial Public Offerings (IPOs).</p>
                           </AccordionBody>
                       </AccordionCollapse>
                   </AccordionItem>

                   <AccordionItem eventKey={'opportunities'}>
                       <AccordionHeader>
                           Focusing on FBG-Supported Opportunities
                       </AccordionHeader>
                       <AccordionCollapse eventKey={'opportunities'}>
                           <AccordionBody>
                               <p>We prioritize investment opportunities that subscribe to FBG services, ensuring a
                                   concentrated effort on business growth and increased chances of success. While this
                                   is our primary focus, we remain open to exceptional cases outside of this
                                   criterion.</p>
                               <p className={'pt-3'}>Read more about <a rel="noreferrer" target={'_blank'}
                                                                        href={'https://www.fairbusinessgrowth.com'}>FBG</a>
                               </p>
                           </AccordionBody>
                       </AccordionCollapse>
                   </AccordionItem>
               </Accordion>
           </Col>

           <Col lg={6} className="my-5">
                <img
                    className="rounded-4"
                    alt=""
                    src={img1}
                />
            </Col>
        </Row>
    </Container>
  )
}

export default PitchTop
