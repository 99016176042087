import React from 'react'
import './Footer.scss'
import {Col, Container, Row} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

const Footer = () => {
    return (
        <footer>
            <Container className="footer">
                <Row className={'justify-content-between py-4'}>
                    <Col lg={4} className="copyright-2023-all py-2">
                        Copyright© 2024 All rights reserved
                    </Col>
                    <Col lg={8} className={'py-2'}>
                        <Navbar className={'justify-content-end'}>
                            <ul className="navbar-nav">
                                <li className={'nav-item'}>
                                    <a className={'nav-link'} href="/contact-us/">
                                        Contact US
                                    </a>
                                </li>
                                <li className={'nav-item'}>
                                    <a className={'nav-link'} href="/who-we-are/">
                                        Who We Are
                                    </a>
                                </li>
                                <li className={'nav-item'}>
                                    <a className={'nav-link'} href="/careers/">
                                        Careers
                                    </a>
                                </li>
                            </ul>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
