import React from 'react'
import {Col} from "react-bootstrap";

const AboutUsPoster = () => {
    return (
        <Col className="aboutUsPoster py-5">
            <div className="aboutUsPoster-wrapper">
                <div className="about-us1">ABOUT</div>
                <div className="about-us2">ABOUT US</div>
            </div>
        </Col>
    )
}

export default AboutUsPoster
