import "./Contact.scss";
import {Button} from "react-bootstrap";

const Contact = () => {

    const contactCardData = [
        {
            title: "Looking for Funding?",
            description: "Investing in pre-seed and seed startups and helping them to successful strategy and execution through our extensive global scaling expertise, structure and network.",
            url: "/pitch-us/funding/"
        },
        {
            title: "Selling your Business?",
            description: "Acquiring small and medium sized established companies and develop and scale them to global successful brands and companies.",
            url: "/pitch-us/selling/"
        }
    ];

    return (
        <div className="contact">
            <div className="contact-cards">
                {contactCardData.map((item) => (
                    <div className="contact-card">
                        <div className="contact-description"><p className={'text-center'}>{item.description}</p></div>
                        <div className="contact-title justify-content-center">
                            <Button variant={'light'} href={item.url} className={'rounded-pill'}>{item.title}</Button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Contact;
