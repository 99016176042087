import React from 'react'
import NewsPoster from './NewsPoster';
import './News.css';
import PressReleases from './PressReleases';
import MediaKit from './MediaKit';
import LatestArticle from './LatestArticle';

const News = () => {
    return (
        <div>
        <NewsPoster/>
        <LatestArticle />
        <PressReleases/>
        <MediaKit />
        </div>
    )
}

export default News