import React from 'react'
import "./Pitch.scss";
import PitchForm from './PitchForm'
import PitchTop from './PitchTop'
import PitchBottom from './PitchBottom'
import {Container} from "react-bootstrap";

export default function Pitch(props) {
  return (
    <Container fluid={true} style={{background:'#FFFCF4'}}>
      <PitchTop />
      <PitchBottom />
      <PitchForm {...props}/>
    </Container>
  )
}
