import React from 'react'
import img1 from "./Frame9.png"
import {Col, Row} from "react-bootstrap";

export default function LatestArticle() {

    const topArticles = [
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        },
        {
            image: img1,
            date: 'February 20, 2023',
            title: 'Lorem ipsum dolor sit amet consectetur.',
        }
    ]
  return (
    <div className='pressReleases'>
        <div className='pressReleases-title'>Latest Articles</div>
        <Row className={'pressReleases-items py-5'}>
            <Col md={4} className={'pressReleases-item-left'}>
                    <div className={'pressReleases-item-left-container'}>
                        <img src={img1} alt="s" />
                    </div>
                    <h2>Lorem ipsum dolor sit amet consectetur.</h2>
                    <a href='#a'>Read more →</a>
            </Col>
            <Col md={8} className={'pressReleases-item-right px-5'}>
                <Row>
                    {
                        topArticles.map( (topArticle) => (
                        <Col md={6} className={'pb-3 border-bottom'}>
                            <div className={'pressReleases-item pb-3'}>
                                <img src={topArticle.image} alt={''}/>
                                <h2 className={'py-3'}>{topArticle.title}</h2>
                                <span>{topArticle.date}</span>
                            </div>
                        </Col>
                        ))
                    }
                </Row>
            </Col>
        </Row>
    </div>
  )
}
