import './Career.scss'
import CareerPoster from "./CareerPoster";
import MakeYourMark from "./MakeYourMark";
import OurValues from './OurValues';
const Career = () => {
    return (
        <>
            <CareerPoster />
            <MakeYourMark/>
            <OurValues/>
        </>
    );
};

export default Career;
