import "./About.scss";
import AboutUsPoster from './AboutUsPoster'
import AboutUsBody from './AboutUsBody'
import {Container, Row} from "react-bootstrap";

const About = () => {
    return (
        <Container fluid={true}>
            <Row className="about flex-column">
                <AboutUsPoster />
                <AboutUsBody />
            </Row>
        </Container>
    );
};

export default About;

