import React from 'react'
import './JoinOurTeam.css'
import {Button, Col, Container, Row} from "react-bootstrap";

const JoinOurTeam = () => {
    return (
        <>
            <Container fluid={true} className='joinOurTeam subscribe'>
                <Row className={'py-5'}>
                    <Col className={'text-center'}>
                        <div className='subscribe-text'>Join Our Team</div>
                        <Button size={'lg'} className={'my-4 rounded-pill'} variant={'primary'} href={'/careers/'}>See Opportunities</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default JoinOurTeam
