import React from 'react'
import './Subscribe.scss'
import {Button, Col, Container, Row} from "react-bootstrap";

const Subscribe = () => {
    return (
        <Container fluid={true}>
            <Row className='subscribe flex-column py-5'>
                <Col className='subscribe-text py-5'>
                    Never Miss An Update <br/>  and receive our quarterly report
                </Col>
                <Col className={'text-center justify-content-center'}>
                    <div className={'newsletter-form-container mx-auto'}>
                        <form className={'newsletter-form'}>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control bg-transparent border border-secondary rounded-start-pill border-end-0 ps-4" placeholder="Email" aria-label="Email" aria-describedby="newsletter-email" />
                                <span className="input-group-text bg-transparent border border-secondary rounded-end-pill border-start-0" id="newsletter-email">
                                    <Button variant={'transparent'} className={'border-secondary rounded-pill'}>Subscribe</Button>
                                </span>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Subscribe
