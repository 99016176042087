import "./Vision.scss";
import img2 from './pexels-cottonbro-studio-4065895 1.png'
import {Col, Container, Row} from "react-bootstrap";

const VisionCardLeft = () => {
    return (
        <Container fluid={true} className={'vision py-5'}>
            <Container className={'py-lg-5 mw-100'}>
                <Row>
                    <Col lg={6}>
                        <div className="vision-text pb-5">
                            <div className="font-soria largePlainText vision-text-head">We invest in the entrepreneurs of tomorrow!</div>
                            <p className="smallPlainText">Our investment group is a scaling engine, not just a capital injection vehicle. We build successful businesses with strongly driven and ambitious entrepreneurs and execution teams.</p>
                        </div>
                    </Col>
                    <Col lg={6} className={'d-none d-sm-block'}>
                        <div className="vision-img">
                            <img
                                className="w-75 mw-100 rounded-3 float-lg-end"
                                alt="abc"
                                src={img2}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default VisionCardLeft;
